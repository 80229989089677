<template>

	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>产品管理</p>
			<i>/</i>
			<p @click="jumpPage">标准产品列表</p>
			<i>/</i>
			<p class="active">标准产品编辑</p>
		</nav>

		<div class="page_edit">

			<div class="page_edit_form">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
					<el-form-item label="" prop="cat_id">
						<p class="form_label_p">产品类型</p>
						<el-select class="input_long" v-model="ruleForm.cat_id" :disabled="disabled"
							@change="goodsClassFn" filterable placeholder="请选择产品类型(用户关联需要分类/请优先选择)">
							<el-option v-for="item in goodsTypeList" :key="item.id" :label="item.cat_name"
								:value="item.id"  :disabled="item.status == '1' ? false : true">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="" prop="name">
						<p class="form_label_p">产品名称</p>
						<el-input class="input_long" v-model="ruleForm.name" placeholder="请输入产品名称(文本20)">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="alias">
						<p class="form_label_p">产品别称</p>
						<el-input class="input_long" v-model="ruleForm.alias" placeholder="请输入产品别称(只能是英文或英文+数字)">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="sort">
						<p class="form_label_p">排序</p>
						<el-input class="input_long" v-model="ruleForm.sort" placeholder="请输入排序(整数越小越靠前)">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="rank">
						<p class="form_label_p">产品级别</p>
						<el-radio-group v-model="ruleForm.rank">
							<el-radio :disabled="disabled" label="1">平台级</el-radio>
							<el-radio :disabled="disabled" label="2">用户级</el-radio>
							<el-radio :disabled="disabled" label="3">设备级</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="" prop="admin_url" v-if="ruleForm.rank == '1' || ruleForm.rank == '2'">
						<p class="form_label_p">管理后台url</p>
						<el-input class="input_long" v-model="ruleForm.admin_url" placeholder="请输入管理后台url">
						</el-input>
					</el-form-item>
					<div class="form_img_up">
						<p class="title">产品图片</p>
						<nav>
							<li v-for="(item,index) in imgUp" :key="index">
								<el-upload :action="$upImg" :on-remove="() => {onRemove(index)}"
									ref="upload" :limit="1" :before-upload="beforeAvatarUpload"
									:on-success="response => {uploadSuccess(response, index)}" :data="imageData" :show-file-list="true"
									list-type="picture" :file-list="item.fileList">
									<div class="img_up_style">
										<p class="p1">400*400</p>
										<p class="p2">上传图片</p>
									</div>
								</el-upload>
							</li>
						</nav>
					</div>
					<el-form-item label="" prop="des" class="el-form-item_inpu_textarea">
						<p class="form_label_p">产品描述</p>
						<el-input class="input_long" type="textarea" v-model="ruleForm.des" :rows="4" maxlength="300"
							show-word-limit placeholder="请输入产品描述(文本300)">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="" v-if="ruleForm.rank == '1'">
						<p class="form_label_p">关联用户级产品</p>
						<div v-if="userData.length > 0" class="goods_option_item">
							<el-checkbox-group v-model="ruleForm.related_user">
								<el-checkbox v-for="item in userData" :label="item.id" :key="item.id">{{item.title}}
								</el-checkbox>
							</el-checkbox-group>
						</div>
						<div v-else class="no_data">
							<p>—— ——</p>
						</div>
					</el-form-item>
					<el-form-item label="" prop="" v-if="ruleForm.rank == '1'">
						<p class="form_label_p">关联设备级产品</p>
						<div v-if="deviceData.length > 0" class="goods_option_item">
							<el-checkbox-group v-model="ruleForm.related_device">
								<el-checkbox v-for="item in deviceData" :label="item.id" :key="item.id">{{item.title}}
								</el-checkbox>
							</el-checkbox-group>
						</div>
						<div v-else class="no_data">
							<p>—— ——</p>
						</div>
					</el-form-item>
					<el-form-item label="" prop="price">
						<p class="form_label_p">单价</p>
						<el-input class="input_long" v-model="ruleForm.price" placeholder="请输入整数">
						</el-input>
						<span class="form_label_span">元/月</span>
					</el-form-item>
					<el-form-item label="" prop="unit">
						<p class="form_label_p">计量单位</p>
						<el-input class="input_long" v-model.trim="ruleForm.unit" placeholder="请输入计量单位">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="is_sale">
						<p class="form_label_p">状态</p>
						<el-radio-group v-model="ruleForm.is_sale">
							<el-radio label="1">上架</el-radio>
							<el-radio label="0">下架</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item class="el-form-item-preservation">
						<div class="page_edit_preservation">
							<p class="p1" @click="upPage">返回</p>
							<p class="p2" v-if="modifyBtn" @click="createForm('ruleForm')">保存</p>
							<p class="p2" v-else @click="editForm('ruleForm')">保存</p>
						</div>
					</el-form-item>
				</el-form>
			</div>

		</div>

	</div>





</template>

<script>
	export default {
		name: 'standardGoodsEdit',
		data() {
			return {
				ruleForm: {
					cat_id: '', // 产品类型
					name: '', // 产品名称
					alias: '', // 别称
					rank: '', // 产品级别
					price: '', // 单价
					unit: '', // 计量单位
					sort: '', // 排序
					is_sale: '1', // 状态
					des: '', // 备注
					related_user: [], // 用户级
					related_device: [], // 设备级
					admin_url: '', // 管理后台url
				},
				rules: {
					name: [{
						required: true,
						message: '名称不能为空',
						trigger: 'blur'
					}],
					price: [{
							required: true,
							message: '单价不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^[0-9]+(.[0-9]{1,2})?$/,
							message: '最多两位小数的数字'
						}
					],
					cat_id: [{
						required: true,
						message: '请选择产品分类',
						trigger: 'change'
					}],
					rank: [{
						required: true,
						message: '请选择产品级别',
						trigger: 'change'
					}],
					unit: [{
						required: true,
						message: '计量单位不能为空',
						trigger: 'blur'
					}],
					des: [{
						required: true,
						message: '产品描述不能为空',
						trigger: 'blur'
					}],
					sort: [{
						pattern: /^\+?[1-9][0-9]*$/,
						message: '只能是非0的正整数',
					}],
					admin_url: [{
						required: true,
						message: '管理后台url不能为空',
						trigger: 'blur'
					}],
				},
				modifyBtn: true, // 默认创建
				disabled: false, // 默认可修改
				goodsTypeList: [], // 产品类型
				imageData: {
					'ratio': '110x84,375x240',
					'rootPath': 'gg'
				}, // 上传图片时携带的参数和上传到哪里
				imgUp: [{
						id: 1,
						fileList: [],
						serveImgList: [],
					},
					{
						id: 2,
						fileList: [],
						serveImgList: [],
					},
					{
						id: 3,
						fileList: [],
						serveImgList: [],
					},
					{
						id: 4,
						fileList: [],
						serveImgList: [],
					},
					{
						id: 5,
						fileList: [],
						serveImgList: [],
					},
				], // 图片数组
				serveImg: [], // 提交图片给后端
				userData: [], // 用户级产品
				deviceData: [], // 设备级
				checkAll1: false,
				checkAll2: false,
			}
		},
		components: {},
		created() {
			if (this.$route.query.id) {
				this.modifyBtn = false;
				this.disabled = true;
				this.getGoodsInfo();
			}

			// 获取产品分类
			this.getGoodsClassList();


		},
		methods: {

			// 获取产品分类
			getGoodsClassList() {
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}products/catlist`,
					data: {}
				}).then(res => {
					if (res.code == 0) {
						this.goodsTypeList = res.data;
					}
				})
			},

			// 选择产品分类
			goodsClassFn(val) {
				console.log(val)
				// 获取用户级
				this.getUserDeviceList(val, 2);

				// 获取设备级
				this.getUserDeviceList(val, 3);
			},

			// 获取用户级和设备级
			getUserDeviceList(id, type) {
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}products/relatedproductlist`,
					data: {
						cat_id: id,
						rank: type
					}
				}).then(res => {
					if (res.code == '0') {
						if (type == 2) {
							this.userData = res.data;
						} else {
							this.deviceData = res.data;
						}
					}
				})
			},

			// 获取产品详情做修改
			getGoodsInfo() {
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}products/detail`,
					data: {
						id: this.$route.query.id,
					}
				}).then(res => {
					if (res.code == '0') {
						this.ruleForm.cat_id = res.data.cat_id;
						this.ruleForm.name = res.data.title;
						this.ruleForm.alias = res.data.alias;
						this.ruleForm.rank = res.data.rank;
						this.ruleForm.is_sale = res.data.is_sale;
						this.ruleForm.unit = res.data.unit;
						this.ruleForm.des = res.data.des;
						this.ruleForm.sort = res.data.sort;
						this.ruleForm.price = res.data.price;
						this.ruleForm.admin_url = res.data.admin_url;

						if (res.data.cat_id && res.data.rank == '1') {
							if (res.data.related_user.length > 0) {
								this.ruleForm.related_user = res.data.related_user;
							}

							if (res.data.related_device.length > 0) {
								this.ruleForm.related_device = res.data.related_device;
							}

							// 获取用户级
							this.getUserDeviceList(res.data.cat_id, 2);

							// 获取设备级
							this.getUserDeviceList(res.data.cat_id, 3);
						}
						
						
						// 用于提交
						if (res.data.images && res.data.images.length > 0) {
							res.data.images.forEach((item, index) => {
								this.imgUp[index].serveImgList.push({
									'110x84': item.small_url,
									'375x240': item.mid_url,
									'source': item.img_url
								})
							})
						}
						
						// 用于展示
						if (res.data.images_url && res.data.images_url.length > 0) {
							res.data.images_url.forEach((item, index) => {
								this.imgUp[index].fileList.push({
									url: item.small_url
								})
							})
						}
						
					}
				})
			},

			// 全选
			handleCheckAllChange1(val) {
				this.checkedCities = val ? this.cities : [];
				this.isIndeterminate = false;
			},

			// 单选
			handleCheckedCitiesChange1(value) {
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.cities.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
			},

			// 删除文件时的钩子函数
			onRemove(index) {
				this.imgUp[index].serveImgList.splice(0, 1);
			},

			// 上传前的钩子函数
			beforeAvatarUpload(file) {
				const isLt2M = file.size / 1024 / 1024 < 1;
				if (!isLt2M) {
					this.$message({
						message: '上传图片大小不能超过 10MB!',
						type: 'warning',

					});
					return;
				}
				return isLt2M
			},

			// 图片上传成功
			uploadSuccess(response, index) {
				this.imgUp[index].serveImgList.push(response);
			},

			// 创建
			createForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						this.imgUp.forEach( item => {
							if(item.serveImgList.length > 0){
								this.serveImg.push(item.serveImgList[0])
							}
						})
						
						if (this.serveImg.length == 0) {
							this.$alert('请至少上传一张商品图片', '产品图片缺失', {
								confirmButtonText: '确定',
								callback: action => {
									console.log(action)
								}
							});
							return;
						}

						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}products/add`,
							data: {
								cat_id: this.ruleForm.cat_id,
								title: this.ruleForm.name,
								alias: this.ruleForm.alias,
								rank: this.ruleForm.rank,
								price: this.ruleForm.price,
								unit: this.ruleForm.unit,
								des: this.ruleForm.des,
								sort: this.ruleForm.sort,
								is_sale: this.ruleForm.is_sale,
								admin_url: this.ruleForm.admin_url,
								related_user: JSON.stringify(this.ruleForm.related_user),
								related_device: JSON.stringify(this.ruleForm.related_device),
								images: JSON.stringify(this.serveImg),
							}
						}).then(res => {
							if (res.code == '0') {
								this.$message({
									message: '创建成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})


					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 修改
			editForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						this.imgUp.forEach( item => {
							if(item.serveImgList.length > 0){
								this.serveImg.push(item.serveImgList[0])
							}
						})
						
						if (this.serveImg.length == 0) {
							this.$alert('请至少上传一张商品图片', '产品图片缺失', {
								confirmButtonText: '确定',
								callback: action => {
									console.log(action)
								}
							});
							return;
						}

						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}products/edit`,
							data: {
								cat_id: this.ruleForm.cat_id,
								title: this.ruleForm.name,
								alias: this.ruleForm.alias,
								rank: this.ruleForm.rank,
								price: this.ruleForm.price,
								unit: this.ruleForm.unit,
								des: this.ruleForm.des,
								sort: this.ruleForm.sort,
								is_sale: this.ruleForm.is_sale,
								admin_url: this.ruleForm.admin_url,
								related_user: JSON.stringify(this.ruleForm.related_user),
								related_device: JSON.stringify(this.ruleForm.related_device),
								images: JSON.stringify(this.serveImg),
								id: this.$route.query.id,
							}
						}).then(res => {
							if (res.code == '0') {
								this.$message({
									message: '修改成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 返回上一页
			upPage() {
				this.$confirm('系统可能不会保存您所填写的信息。', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {});
			},

			// 头部跳转页面
			jumpPage() {
				this.$router.go(-1);
			},

		}
	}
</script>
<style scoped lang="less">
	/* 加长input */
	.input_long_plus {
		width: 500px;
	}

	/* 长input */
	.input_long {
		width: 400px;
	}

	/* 中等input */
	.input_medi {
		width: 200px;
	}

	/* mini input */
	.input_mini {
		width: 100px;
	}

	.form_img_up {
		padding: 24px;
		background-color: #fff;

		.title {
			font-size: 14px;
			color: rgba(0, 0, 0, .85);
			height: 44px;
			line-height: 44px;
		}

		nav {
			display: flex;
			justify-content: flex-start;

			li {
				width: 134px;
				height: 134px;
				border: 1px dashed rgba(0, 0, 0, 0.2);
				background-color: rgba(0, 0, 0, .02);
				margin-right: 16px;
				position: relative;

				.img_up_style {
					width: 134px;
					height: 134px;

					.p1 {
						font-size: 14px;
						color: rgba(0, 0, 0, .45);
						margin-top: 36px;
					}

					.p2 {
						width: 90px;
						height: 32px;
						line-height: 32px;
						text-align: center;
						border-radius: 2px;
						border: 1px solid rgba(0, 0, 0, .15);
						margin: 16px auto 0;
					}
				}

			}
		}
	}

	.no_data {
		color: rgba(0, 0, 0, .65);
	}

	.relation_goods {
		padding: 24px;
		background-color: #fff;

		.title {
			font-size: 14px;
			line-height: 40px;
			color: rgba(0, 0, 0, .85);
		}

		.goods_option_item {
			display: flex;
			justify-content: flex-start;
			margin-bottom: 8px;

			.one_select_all,
			.two_single_choice {
				padding: 12px;
				border: 1px solid rgba(0, 0, 0, 0.2);
			}

			.two_single_choice {
				border-left: none;
			}

		}
	}
</style>
